import React from 'react';
import Layout from 'layout';
import { ExtendGridBox } from 'pages/integrations/analytics';
import SideBar from 'components/marketplace/sideBar';
import Content from 'components/marketplace/content';
import Banner from 'components/marketplace/banner';
import { frontmatter } from 'data/marketplace/integrations';
import arrow from 'img/ui/arrow_black.svg';
import OwnAppSection from 'components/marketplace/ownAppSection';

const Developers = () => {
  const [mostPopularCard] = frontmatter.items.filter((item) => item.title === 'Gatsby');
  return (
    <Layout
      metaTitle="Developers | Integrations"
      canonical="/integrations/for-developers/"
      metaDescription="See how the users interact with the app and find out what caused the error. LiveSession helps with spotting bugs in the process."
    >
      <div className="container">
        <ExtendGridBox>
          <div className="breadcrumbs">
            <p>
              <a href="/integrations/">Integrations</a>
            </p>
            <img src={arrow} alt="arrow" title="Arrow" />
            <p>
              <a href="/integrations/for-developers/">
                <strong>Developers</strong>
              </a>
            </p>
          </div>
          <SideBar activeCategory="Developers" />
          <Content activeCategory="Developers" hideTitle>
            <Banner
              activeCategory="Developers"
              card={mostPopularCard}
              text="See how the users interact with the app and find out what caused the error. LiveSession helps with spotting bugs in the process."
            />
          </Content>
        </ExtendGridBox>
        <OwnAppSection />
      </div>
    </Layout>
  );
};

export default Developers;
